<template>
  <div>
    <PageHeader />
    <b-row>
      <b-col>
        <template v-if="isFetchingRoles">
          <b-skeleton
            animation="wave"
            width="55%"
          ></b-skeleton>
          <b-skeleton
            animation="wave"
            width="70%"
          ></b-skeleton>
        </template>
        <b-form-group v-else>
          <b-form-radio-group
            v-model="selectedRoleId"
            button-variant="outline-dark"
            buttons
          >
            <b-form-radio
              v-for="(role, index) in roles"
              :key="index"
              :value="role.id"
            >
              {{ role.name || ''}}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="2"
        class="text-right"
      >
        <b-button
          v-if="$allowPermission('agent:manage.role')"
          variant="outline-primary"
          @click="isRoleFormModalActive = !isRoleFormModalActive"
        >
          <i class="uil-plus mr-1"></i>
          {{ $t('buttons.add') }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="isFetchingRole">
      <b-card>
        <RoleForm 
          :value="roleDetail"
          :is-loading="isUpdating"
          :readonly="$allowPermission('agent:manage.role')"
          @submit="onSubmit"
        />
      </b-card>
    </b-overlay>
    <b-modal
      v-model="isRoleFormModalActive"
      :title="`${$t('buttons.add')} - ${$t('roles.title')}`"
      size="lg"
      hide-footer
    >
      <RoleForm
        :is-loading="isAdding"
        @submit="onSubmit"
        @cancel="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'ตั้งค่าหน้าที่',
  },
  components: {
    RoleForm: () => import('@components/forms/role-form.vue'),
  },
  data() {
    return {
      isRoleFormModalActive: false,
      selectedRoleId: null,
    }
  },
  computed: {
    ...mapState({
      isFetchingRoles: (state) => state.role.isFetchingRoles,
      isFetchingRole: (state) => state.role.isFetchingRole,
      isAdding: (state) => state.role.isAddingRole,
      isUpdating: (state) => state.role.isUpdatingRole,
    }),
    ...mapGetters(['roles', 'roleDetail']),
  },
  watch: {
    roles(list) {
      if(list && list[0] && !this.selectedRoleId){
        list[0].id
      }
    },
    selectedRoleId(val) {
      if (val) {
        this.fetchRoleDetail()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchRoles',
      'fetchRole',
      'createRole',
      'updateRole',
    ]),
    async fetchData() {
      await this.fetchRoles({
        limit: '',
        page: '',
        search: '',
        masterId: '',
        agentId: '',
      })
      if (this.roles && this.roles[0]) {
        this.selectedRoleId = this.roles[0].id
      }
    },
    fetchRoleDetail() {
      if(this.selectedRoleId) {
        this.fetchRole(this.selectedRoleId)
      }
    },
    onCloseModal() {
      this.isRoleFormModalActive = false
    },
    async onSubmit(form) {
      if (form.id) {
        this.updateRole({
          id: form.id,
          data: form,
        })
      } else {
        await this.createRole(form)
        this.onCloseModal()
      }
    },
  },
}
</script>
